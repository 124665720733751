/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.box,
.wrapper {
  color: #fff;
  padding: 10px;
  border: 1px solid #ccc;

  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.box1,
.wrapper1 {
  /* padding: 4px; */
  border: 1px solid #ccc;

  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05);
}


.text-brown {
  color: #8B4513;
}

.bg-brown {
  background-color: #8B4513;
}

.decoration-gold {
  text-decoration-color: #FFD700;
}

@media (max-width: 500px) {
  #navSearch{
    display: none;
  }
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: center;
}
.text-right {
  text-align: right;
}

.builtBy{
  font-size: 10px;
  margin-right: 20px;
  color: #ccc;
}